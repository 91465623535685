import Vue from 'vue';

const EventBus = new Vue();

EventBus.listenerReady = false;

EventBus.myReportsListenerReady = false;
EventBus.myReportsWithIDListenerReady = false;

EventBus.campaignsReady = false;
EventBus.mediaReady = false;
EventBus.creativesReady = false;
EventBus.audienceReady = false;

export default EventBus;
